import React from "react";

const Image = () => {
  return (
    <img
      id="bgImg"
      src="./assets/Images/photoPermisOlwen3.png"
      alt="my passfoto"
    />
  );
};

export default Image;
